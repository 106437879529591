html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  /* overflow-x: hidden; */
}

.layout{
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.open-nav{
  overflow-y: hidden;
}

* {
  font-family: 'Inter', sans-serif;
}
