@import '../../helpers/helperStyle.scss';
@import '../../helpers/breakpoints.scss';

.paragraphWhite {
  @include ParagraphWhite;
}
.horizontalLine {
  @include HorizontalLine;
}

.top-part {
  display: flex;
  height: 160px;
  padding: 0px 5%;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #27292b;

  @include md {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    text-align: center;
  }
}

.bottom-part {
  display: flex;
  flex-direction: column;
  background: #3b3f44;
  height: 300px;
  padding: 80px 5%;
  gap: 30px;
  height: fit-content;

  .navigation-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 80px;

    @include md {
      flex-direction: column;
      justify-content: center;
      gap: 30px;
    }


    .iso-img {
      aspect-ratio: 1281/650;
      width: 200px;

      img {
        width: 100%;
      }
    }
  }

  .footer-navigation-card {
    display: flex;
    flex-direction: column;
    width: 160px;
    gap: 10px;
  }

  .footer-item-title {
    font-size: 20px;
    margin-bottom: 25px;

    @include md {
      margin-bottom: 15px;
    }
  }

  .footer-item-link {
    font-size: 18px;
  }

  .contact-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    @include md {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      gap: 30px;
    }

    span {
      font-size: 16px;
    }
  }

  .copyright {
    @include md {
      flex-direction: column-reverse;
      text-align: center;
      align-items: center;
      width: 100%;
    }
  }
  .footer-social-media-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @include md {
        gap: 50px;
      img {
        height: 30px;
      }
    }
  }
}
