@import '../../helpers/breakpoints.scss';

.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(25px);

  img {
    margin-left: 5%;
  }

  .navbar-routes-wrapper {
    display: flex;
    gap: 30px;
    flex-direction: row;
    margin-right: 5%;
    a {
      text-decoration: none;
      color: var(--Brand-Primary-Grey-800, #2e363c);
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .hamburger-menu {
    position: relative;
    cursor: pointer;

    .hamburger-button {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }
}

.mobile-menu {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  justify-content: center;
  gap: 30px;
  a {
    text-decoration: none;
    color: var(--Brand-Primary-Grey-800, #2e363c);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 300;
  }
}

.mobile-view {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  @include md {
    display: flex;
    flex-direction: column;
  }
}
