@import './breakpoints.scss';

@mixin commonTitleStyle {
  font-family: 'Inter', sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  @include md{
    font-size: 36px;
  }
}

@mixin commonParagraphStyle {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;

  @include md{
    font-size: 18px;
  }
}

@mixin titleBlack {
  @include commonTitleStyle;
  color: var(--Primary-Black-900, #131313);
}

@mixin titleWhite {
  @include commonTitleStyle;
  color: var(--Primary-Grey-200, #e8e8e8);
}
@mixin ParagraphBlack {
  @include commonParagraphStyle;
  color: var(--Primary-Black-900, #131313);
}

@mixin ParagraphWhite {
  @include commonParagraphStyle;
  color: var(--Primary-Grey-200, #e8e8e8);
}
@mixin tabsCommonStyle {
  border-radius: 0;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@mixin HorizontalLine {
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(to right, rgba(183, 33, 38, 1), rgba(146, 146, 146, 1));
  width: 100%;
}
