@import '../../helpers/helperStyle.scss';
@import '../../helpers/breakpoints.scss';

.titleBlack {
  @include titleBlack;
}
.titleWhite {
  @include titleWhite;
}
.paragraphBlack {
  @include ParagraphBlack;
}
.paragraphWhite {
  @include ParagraphWhite;
}

.horizontalLine {
  @include HorizontalLine;
}

.about-us-wrapper {
  .padding {
    padding: 0 5%;
  }

  .banner-section {
    background-image: url('../../assets/images/dotted-earth.svg');
    background-color: #131313;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    width: 100%;
    height: 810px;

    @include md {
      height: fit-content;
    }

    .section-video-wrapper {
      position: relative;
      height: 268px;
      width: 100%;
      display: flex;
      justify-content: center;
      overflow-y: hidden;
      pointer-events:none;

      @include md {
        height: 110px;
      }

      .masked-video {
        position: absolute;
        left: unset;
        right: unset;
        height: 500px;
        top: 0;
        -webkit-mask: url('../../assets/images/np-symbol-mask.svg') top/contain no-repeat;

        @include md {
          height: 200px;
        }
      }
    }

    .section-content {
      width: 67%;
      min-width: 640px;
      max-width: 968px;
      padding-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      flex-direction: column;
      gap: 30px;

      @include md {
        width: 90%;
        min-width: 90%;
      }

      .paragraphWhite {
        font-size: 26px;

        @include md {
          font-size: 20px;
        }
      }

      .about-us-title {
        font-size: 62px;
        line-height: 68.2px;
        font-weight: 400;
        letter-spacing: -2%;
        color: var(--Primary-Grey-200, #e8e8e8);

        @include md {
          font-size: 38px;
          line-height: 45.6px;
        }
      }

      hr {
        border-image-source: linear-gradient(
          to right,
          rgb(19, 19, 19),
          rgb(41, 37, 37),
          rgba(183, 33, 38, 1),
          rgb(41, 37, 37),
          rgb(19, 19, 19)
        );
        width: 100%;
        border-width: 2px;

        @include md {
          border-width: 1px;
        }
      }
    }
  }

  .about-section-one-wrapper {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding: 110px 65px;
    background: #f3f6f7;

    span {
      width: 60%;
    }

    @include md {
      span {
        width: 100%;
      }
    }

    .cards-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      gap: 158px;

      @include md {
        flex-direction: column;
        gap: 40px;

        span {
          width: 100%;
        }
      }

      span {
        width: 90%;
        font-weight: 300;
      }

      .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 40px;
        width: 100%;

        .horizontal-line {
          height: 1px;
          background: #131313;
          width: 100%;
        }

        img {
          height: 30%;
        }
      }
    }
  }

  .about-section-one-wrapper-black {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding: 110px 65px;
    background: #131313;
    color: #f3f6f7;

    .subtitle{
      font-size: 30px;
    }

    span {
      width: 60%;
    }

    @include md {
      span {
        width: 100%;
      }
    }

    .cards-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      gap: 158px;

      @include md {
        flex-direction: column;
        gap: 40px;

        span {
          width: 100%;
        }
      }

      span {
        width: 90%;
        font-weight: 300;
      }

      .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 40px;
        width: 100%;

        .horizontal-line {
          height: 1px;
          background: #B72126;
          width: 100%;
        }

        img {
          height: 30%;
        }
      }
    }
  }

  .about-leaders-section {
    display: flex;
    flex-direction: column;
    padding: 110px 65px;
    gap: 60px;

    .title {
      width: 60%;
    }

    .cards-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      @include md {
        flex-direction: column;
      }

      .card {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid #131313;
        border-top: 1px solid #131313;
        gap: 5px;
        padding: 60px 0;

        @include md {
          gap: 20px;
          padding: 60px 0;
        }

        .row-section {
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 30px;

          @include md {
            flex-direction: column;
          }
        }

        .text-section {
          display: flex;
          flex-direction: column;
          width: 100%;

          .title {
            font-size: 28px;
            @include md {
              font-size: 20px;
            }
          }
          .paragraphRed {
            color: #b72126;
            font-weight: 300;
            font-size: 28px;
            @include md {
              font-size: 20px;
            }
          }

          .description {
            font-family: 'Inter', sans-serif;
            font-size: 20px;
            font-weight: 400;
            text-align: left;
            margin-top: 15px;
            @include md {
              font-size: 16px;
            }
          }
        }

        img {
          width: 360px;
          height: 380px;
          object-fit: cover;
          margin-bottom: 20px;

          @include md {
            width: 100%;
          }
        }
        .quote {
          background: #e7edef;
          padding: 20px 40px;
          border-left: 3px solid;
          border-color: #b72126;
          @include md {
            padding: 20px 30px;
          }

          span {
            color: #b72126;

            @include md {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .about-testimonial-section-root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 100px 5%;
    position: relative;

    .left-side {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 50px;
      border-right: 1px solid black;
      padding-right: 5%;
      height: 300px;
    }
    .right-side {
      width: 100%;

      .testimonial-wrapper {
        display: flex;
        align-items: flex-start;
        width: 70%;
        gap: 50px;
        flex-direction: column;
        text-align: left;
        padding: 0 40px;

        .testimonial-name {
          font-size: 26px;
          font-weight: 300;
        }
        .testimonial-text {
          font-size: 27px;
          font-weight: 300;
        }
      }
    }

    .carousel-button-group-testimonials {
      position: absolute;
      top: 300px;
      left: 5%;
    }
  }

  .about-us-insights {
    display: flex;
    flex-direction: column;
    padding: 100px 0 100px 5%;
    gap: 60px;
    position: relative;

    .react-multi-carousel-item {
      margin: 0 40px;
    }
    .title-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: 5%;
    }
  }
  .arrow-next {
    background-color: transparent;
    border: none;
  }
  .arrow-prev {
    background-color: transparent;
    border: none;
  }

  .carousel-button-group {
    position: absolute;
    top: 100px;
    right: 5%;
  }

  .insight-card-wrapper {
    display: flex;
    border: 1px solid black;
    flex-direction: column;
    padding: 20px;
    text-align: left;
    gap: 20px;
    width: 100%;

    img {
      width: 100%;
      height: 300px;
    }

    .description {
      color: var(--Primary-Black-900, #131313);
      font-family: 'Inter', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
    }

    .date {
      color: var(--Primary-Black-900, #131313);
      font-family: 'Inter', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      opacity: 0.5;
    }
    .horizontal-line {
      border: 1px solid;
      border-color: var(--Secondary-Grey-300, #a3adb6);
      opacity: 0.5;
    }

    .read-more {
      color: var(--Brand-Red-500, #b72126);
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}
