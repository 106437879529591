@import '../../helpers/breakpoints.scss';

.section-video-wrapper {
  position: relative;
  height: 500px;
  width: 100%;
  overflow-x: hidden;
  pointer-events:none;

  .masked-video {
    position: absolute;
    right: 0;
    top: 0;
    height: 500px;
    -webkit-mask: url('../../assets/images/np-symbol.png') right/contain no-repeat;

    @include md {
      -webkit-mask: url('../../assets/images/np-symbol-mobile.png') right/contain no-repeat;
    }
  }
}
