@import '../../helpers/helperStyle.scss';
@import '../../helpers/breakpoints.scss';

.homepage-wrapper {
  width: 100%;
  .titleBlack {
    @include titleBlack;
  }
  .titleWhite {
    @include titleWhite;
  }
  .paragraphBlack {
    @include ParagraphBlack;
  }
  .paragraphWhite {
    @include ParagraphWhite;
  }

  .horizontalLine {
    @include HorizontalLine;
  }
  .section-one-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    justify-content: space-between;

    .section-one-text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      margin-left: 5%;

      @include md {
        width: 90%;
        margin-left: 0;
        margin-bottom: 10px;
        margin-top: 30px;
      }
    }

    @include md {
      flex-direction: column;
    }
  }

  .services-wrapper {
    background-color: var(--Primary-Black-900, #131313);
    padding: 100px 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include md {
      padding: 50px 5%;
      gap: 40px;
    }
    .service-paragraph {
      width: 50%;

      @include md {
        width: 100%;
      }
    }
  }

  .why-us-wrapper {
    background: var(--Secondary-Grey-100, #e7edef);
    width: 90%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    @include md {
      height: fit-content;
      padding: 60px 0px;
      gap: 40px;
    }

    .why-us-paragraph {
      width: 70%;
      text-align: center;
      @include md {
        width: 90%;
      }
    }

    .why-us-signature {
      color: var(--Primary-Black-900, #131313);
      font-family: 'Inter', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      opacity: 0.5;
    }
  }
  .why-us-root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
  }
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: row;
  gap: 40px;
  background-color: var(--Primary-Black-900, #131313);
  padding-left: 5%;

  &::-webkit-scrollbar {
    height: 0;
  }

  @include md {
    gap: 0;
    overflow-x: auto;
    padding: 0;
  }
}

.react-tabs__tab {
  color: white;
  @include tabsCommonStyle;
  padding: 14px 15px;
  transition: color 0.7s ease, padding 0.7s ease;
  white-space: nowrap;

  @include md {
    font-size: 14px;
    line-height: 21px;
    padding: 15.5px 14px;
  }
}

.react-tabs__tab--selected {
  color: black;
  @include tabsCommonStyle;
  padding: 14px 32px;

  @include md {
    font-size: 14px;
    line-height: 21px;
    padding: 15.5px 14px;
  }
}

.react-tabs__tab:focus:after{
  content: none;
}
