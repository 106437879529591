@import '../../helpers/breakpoints.scss';

.service-tabs-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5%;
  gap: 60px;

  @include md{
    flex-direction: column-reverse;
  }

  .right-side {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .left-side {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;

    .tab-item-wrapper {
      border-top: 1px solid #c4d1de;
      text-decoration: none;
      width: 100%;
      padding: 20px 0px;
      color: var(--Primary-Black-900, #131313);
      font-family: 'Inter', sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      transition: color 0.5s ease, padding 0.5s ease;
      display: flex;
      align-self: center;
      justify-content: space-between;

      &:hover {
        background-color: #131313;
        color: #e8e8e8;
        padding: 20px 10px;
      }
    }

    .tab-item-wrapper .hide {
      display: none;
    }

    .tab-item-wrapper:hover .hide {
      display: block;
      background-color: #131313;
      color: #e8e8e8;
      font-size: 18px;
      font-weight: 200;

    }

    .tab-title {
      margin-bottom: 20px;
    }
  }
}
