@import '../../helpers/helperStyle.scss';
@import '../../helpers/breakpoints.scss';

.titleWhite {
  @include titleWhite;
}
.paragraphWhite {
  @include ParagraphWhite;
}

.contact-us-wrapper {
  display: flex;
  flex-direction: row;
  background: var(--Primary-Black-900, #131313);
  padding: 20px 5% 20px 0;
  justify-content: space-between;
  height: fit-content;

  @include md {
    flex-direction: column;
    padding: 0 5% 30px 5%;
    gap: 20px;
  }

  .email-form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .contact-info {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      border: {
        top: solid 1px rgba(232, 232, 232, 0.5); // --Primary-Grey-200, #e8e8e8, 50% opacity
        bottom: solid 1px rgba(232, 232, 232, 0.5); // --Primary-Grey-200, #e8e8e8, 50% opacity
      };
      padding: 30px 0;

      @include md {
        flex-direction: column;
        align-items: flex-start;
      }

      &-item {
        color: var(--Primary-Grey-200, #e8e8e8);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        line-height: 22.4px;
        letter-spacing: -2%;
        font-weight: 400;
        text-decoration: none;
      }

      .divider-vertical {
        height: 25px;
        width: 1px;
        opacity: 50%;
        background: var(--Primary-Grey-200, #e8e8e8);

        @include md {
          width: 100%;
          height: 1px;
        }
      }
    }

    @include md {
      align-items: center;
      justify-content: center;
    }

    .form-label {
      color: var(--Primary-Grey-200, #e8e8e8);
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      .required-label {
        color: var(--Secodary-Grey-300, #a3adb6);
      }
    }

    .form-input {
      background-color: var(--Primary-Black-900, #131313);
      color: #a3adb6;
      border: 1px solid var(--Secondary-Grey-400, #676f75);
      height: 30px;
      padding: 12px;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      &:active {
        border: 1px solid var(--Secondary-Grey-400, #676f75);
      }
    }

    .form-button {
      background: var(--Primary-Grey-200, #e8e8e8);
      display: flex;
      width: 50%;
      height: 48px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      color: var(--Primary-Black-900, #131313);
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      text-transform: uppercase;

      @include md {
        width: 100%;
      }
    }

    .input-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;

      textarea {
        resize: none;
        height: 130px;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 26px;
      width: 100%;

      @include md {
        flex-direction: column;
      }
    }
  }

  .right-side {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .section-video-wrapper {
    position: relative;
    height: 700px;
    width: 100%;
    overflow-x: hidden;
    pointer-events:none;

     @include md {
       height: 200px;
     }

    .masked-video {
      position: absolute;
      left: 0;
      top: 0;
      height: 700px;
      -webkit-mask: url('../../assets/images/np-symbol-left.png') left/contain no-repeat;

      @include md {
         height: 200px;
        -webkit-mask: url('../../assets/images/np-symbol-top.png') center/contain no-repeat;
        top: -10px;
      }
    }
  }
}
